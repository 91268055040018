import Logo from "../../../assets/images/logo/JsaSiddha-png.png";
import { AdmissionMarquee } from "../admissionMrq";
import "./navHead.scss";
export const NavHead = () => {
  return (
    <div className="header">
              <div className="row text-center w-100">
              <AdmissionMarquee />
      <div className="col-2 d-flex align-items-center">
        <a href="/">
          <img
            src={Logo}
             alt="agriculture college"
            className="logo"
            style={{ marginLeft: "5vw", maxWidth: "10vw" }}
          />
        </a>
      </div>
      <div className="col-9 p-3 " style={{ marginLeft: 0 }}>
        <div className="col-12">
          <h2
            className=" fw-bold text-uppercase p-0 mb-0"
            style={{ fontSize: "2.5vw", color: "#000080" }}
          ><b>  JSA Medical College for Siddha and Research Centre</b>
          
          </h2>
        </div>
        <div className="col-12">
          <h2
            style={{ fontSize: "1vw", color: "" }}
            className="  fw-bold text-uppercase p-0 mb-0"
          >
            Free Treatment Siddha Hospital
          </h2>
        </div>
        <div className="col-12">
          <h2 style={{fontSize:"1.5vw",color: "#b74241"  }} className="fw-bold  p-0 mb-0">
            Apporved by Govt.of India, NCISM Ministry of AYUSH-New Delhi.
         </h2>
         </div>
         <div className="col-12">
         <h2 style={{fontSize:"1.5vw",color: "#b74241"  }} className="fw-bold  p-0 mb-0">Govt.of TamilNadu and Affilated to The TN Dr.MGR Medical University,Chennai.</h2>
         </div>
         <div className="col-12">
           <h2 style={{ fontSize: "1.2vw",color: "rgb"    }} className="fw-bold  p-0 mb-0">
            Pali, Ulundurpet, Kallakurichi, TamilNadu-606 104
          </h2>
          </div>
      </div>
    </div>
    {/* <AdmissionMarquee /> */}
    </div>
  );
};
