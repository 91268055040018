import React, { Suspense, lazy } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import Loader from "./components/loader";
import OnlinePayment from "./components/onlinepayment/onlinepayment.js";

const Routers = () => {
  const Home = lazy(() => import("./components/pages/home"));
  const AboutUs = lazy(() => import("./components/pages/about/aboutUs"));
  const AffiliatedUnivercity = lazy(() =>
    import("./components/pages/about/affliatedUnivercity")
  );
  const AwardsAndAcheivements = lazy(() =>
    import("./components/pages/about/awardsAndAcheivements")
  );
  // const AnnualReport = lazy(() =>
  //   import("./components/pages/about/annualReport")
  // );
  const AnnualReport2021 = lazy(() =>
    import("./components/pages/about/annualReport/AnnualReport2021.js")
  );
  const AnnualReport2022 = lazy(() =>
    import("./components/pages/about/annualReport/AnnualReport2022.js")
  );
  const AnnualReport2023 = lazy(() =>
    import("./components/pages/about/annualReport/AnnualReport2023.js")
  );
  const AnnualReport2024 = lazy(() =>
    import("./components/pages/about/annualReport/AnnualReport2024.js")
  );
  const AdmissionIntake = lazy(() =>
    import("./components/pages/admissions/intakeAndApproval")
  );
  const AdmissionsEnquiry = lazy(() =>
    import("./components/pages/admissions/admissionsEnquiry.js")
  );

  const Admission2017 = lazy(() =>
    import("./components/pages/admissions/admission2017_18")
  );
  const Admission2018 = lazy(() =>
    import("./components/pages/admissions/admission2018_19")
  );
  const Admission2019 = lazy(() =>
    import("./components/pages/admissions/admission2019_20")
  );
  const Admission2020 = lazy(() =>
    import("./components/pages/admissions/admission2020_21")
  );
  const Admission2021 = lazy(() =>
    import("./components/pages/admissions/admission2021_22")
  );
  const Admission2022 = lazy(() =>
    import("./components/pages/admissions/admission2022_23")
  );
  const Admission2023 = lazy(() =>
    import("./components/pages/admissions/admission2023_24")
  );
  const Admission2024 = lazy(() =>
    import("./components/pages/admissions/admission2024_25.js")
  );
  const Courses = lazy(() => import("./components/pages/course/courses"));
  const StatusOfRecognition = lazy(() =>
    import("./components/pages/course/recognition")
  );
  const TransitionalCurriculum = lazy(() =>
    import("./components/pages/course/transitionalCurriculam")
  );
  const CourseResults = lazy(() => import("./components/pages/course/results"));
  const TeachingDetails = lazy(() =>
    import("./components/pages/faculty/details/teaching")
  );
  const TeachingAttendance = lazy(() =>
    import("./components/pages/faculty/attendance/teaching")
  );
  const NonTeachingDetails = lazy(() =>
    import("./components/pages/faculty/details/nonTeaching")
  );
  const NonTeachingAttendance = lazy(() =>
    import("./components/pages/faculty/attendance/nonTeaching")
  );
  const HospitalStaffDetails = lazy(() =>
    import("./components/pages/faculty/details/hospital")
  );
  const HospitalStaffAttendance = lazy(() =>
    import("./components/pages/faculty/attendance/hospital")
  );
  const FacilityCollege = lazy(() =>
    import("./components/pages/facilities/college")
  );
  const FacilityHospital = lazy(() =>
    import("./components/pages/facilities/hospital")
  );
  const Ipop2021 = lazy(() => import("./components/pages/facilities/2021.js"));
  const Ipop2022 = lazy(() => import("./components/pages/facilities/2022.js"));
  const Ipop2023 = lazy(() => import("./components/pages/facilities/2023.js"));
  const Ipop2024 = lazy(() => import("./components/pages/facilities/2024.js"));
  const Ipop2025 = lazy(() => import("./components/pages/facilities/2025.js"));
  const ResearchAndDevelopment = lazy(() =>
    import("./components/pages/researchAndDevelopment")
  );
  const CMEWebinarProgram = lazy(() => import("./components/pages/cmeWebinar"));
  const StudentsAttendance2022 = lazy(() =>
    import("./components/pages/studentsCorner/attendance2022")
  );
  const StudentsAttendance2023 = lazy(() =>
    import("./components/pages/studentsCorner/attendance2023")
  );
  const StudentsPosters = lazy(() =>
    import("./components/pages/studentsCorner/posters")
  );
  const Gallery = lazy(() => import("./components/pages/gallery"));
  const MedicalCamp2024 = lazy(() =>
    import("./components/pages/gallery/2024/medicalcamp.js")
  );
  const Event2024 = lazy(() =>
    import("./components/pages/gallery/2024/event.js")
  );
  const NewsAndUpdates = lazy(() => import("./components/pages/news/news.js"));
  const Moments2023 = lazy(() => import("./components/pages/gallery/2023"));
  const Moments2022 = lazy(() => import("./components/pages/gallery/2022.js"));
  const Moments2021 = lazy(() => import("./components/pages/gallery/2021"));
  const Moments2020 = lazy(() => import("./components/pages/gallery/2020"));
  // const Moments2019  = lazy(() => import("./components/pages/gallery/2019"));
  const ContactUs = lazy(() => import("./components/pages/contactUs"));
  const Event = lazy(() => import("./components/pages/latestEvent/Event"));
  const MedicalCamp = lazy(() =>
    import("./components/pages/latestEvent/MedicalCamp.js")
  );

  const Event2025 = lazy(() =>
    import("./components/pages/gallery/2025/event.js")
  );
  const MedicalCamp2025 = lazy(() =>
    import("./components/pages/gallery/2025/medicalcamp.js")
  );
  const OnlinePayment = lazy(() =>
    import("./components/onlinepayment/onlinepayment.js")
  );

  return (
    <Suspense fallback={<Loader />}>
      {" "}
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/about-us" element={<AboutUs />} />
        <Route
          exact
          path="/affiliated-univercities"
          element={<AffiliatedUnivercity />}
        />
        <Route
          exact
          path="/awards-and-acheivements"
          element={<AwardsAndAcheivements />}
        />
        {/* <Route exact path="/Annual-Report/:year" element={<AnnualReport />} /> */}
        <Route
          exact
          path="/Annual-Report/2020-2021"
          element={<AnnualReport2021 />}
        />
        <Route
          exact
          path="/Annual-Report/2021-2022"
          element={<AnnualReport2022 />}
        />
        <Route
          exact
          path="/Annual-Report/2022-2023"
          element={<AnnualReport2023 />}
        />
        <Route
          exact
          path="/Annual-Report/2023-2024"
          element={<AnnualReport2024 />}
        />
        <Route
          exact
          path="/Admissions/intake_and_approval"
          element={<AdmissionIntake />}
        />
        <Route
          exact
          path="/admissionsenquiry"
          element={<AdmissionsEnquiry />}
        />
        <Route exact path="/Admissions/2017-2018" element={<Admission2017 />} />
        <Route exact path="/Admissions/2018-2019" element={<Admission2018 />} />
        <Route exact path="/Admissions/2019-2020" element={<Admission2019 />} />
        <Route exact path="/Admissions/2020-2021" element={<Admission2020 />} />
        <Route exact path="/Admissions/2021-2022" element={<Admission2021 />} />
        <Route exact path="/Admissions/2022-2023" element={<Admission2022 />} />
        <Route exact path="/Admissions/2023-2024" element={<Admission2023 />} />
        <Route exact path="/Admissions/2024-2025" element={<Admission2024 />} />
        <Route exact path="/courses" element={<Courses />} />
        <Route
          exact
          path="/status-of-recognition"
          element={<StatusOfRecognition />}
        />
        <Route
          exact
          path="/transitional-curriculum"
          element={<TransitionalCurriculum />}
        />
        <Route exact path="/results" element={<CourseResults />} />
        <Route
          exact
          path="/faculty/teaching-staff/details"
          element={<TeachingDetails />}
        />
        <Route
          exact
          path="/faculty/teaching-staff/attendance"
          element={<TeachingAttendance />}
        />
        <Route
          exact
          path="/faculty/non-teaching-staff/details"
          element={<NonTeachingDetails />}
        />
        <Route
          exact
          path="/faculty/non-teaching-staff/attendance"
          element={<NonTeachingAttendance />}
        />{" "}
        <Route
          exact
          path="/faculty/hospital-staff/details"
          element={<HospitalStaffDetails />}
        />
        <Route
          exact
          path="/faculty/hospital-staff/attendance"
          element={<HospitalStaffAttendance />}
        />
        <Route exact path="/facilities/college" element={<FacilityCollege />} />
        <Route
          exact
          path="/facilities/hospital"
          element={<FacilityHospital />}
        />
        <Route
          exact
          path="/facilities/hospital/ip&op2021"
          element={<Ipop2021 />}
        />
        <Route
          exact
          path="/facilities/hospital/ip&op2022"
          element={<Ipop2022 />}
        />
        <Route
          exact
          path="/facilities/hospital/ip&op2023"
          element={<Ipop2023 />}
        />
        <Route
          exact
          path="/facilities/hospital/ip&op2024"
          element={<Ipop2024 />}
        />
        <Route
          exact
          path="/facilities/hospital/ip&op2025"
          element={<Ipop2025 />}
        />
        <Route
          exact
          path="/research-and-development"
          element={<ResearchAndDevelopment />}
        />
        <Route exact path="/gallery" element={<Gallery />} />
        <Route exact path="/news&updates" element={<NewsAndUpdates />} />
        <Route exact path="/gallery/event/2025" element={<Event2025 />} />
        <Route
          exact
          path="/gallery/medicalcamp/2025"
          element={<MedicalCamp2025 />}
        />
        <Route exact path="/gallery/event/2024" element={<Event2024 />} />
        <Route
          exact
          path="/gallery/medicalcamp/2024"
          element={<MedicalCamp2024 />}
        />
        <Route exact path="/gallery/2023" element={<Moments2023 />} />
        <Route exact path="/gallery/2022" element={<Moments2022 />} />
        <Route exact path="/gallery/2021" element={<Moments2021 />} />
        <Route exact path="/gallery/2020" element={<Moments2020 />} />
        {/* <Route exact path="/gallery/2019" element={<Moments2019 />} /> */}
        <Route exact path="/cme-webinar" element={<CMEWebinarProgram />} />
        <Route
          exact
          path="/students-corner/attendance/2022-2023"
          element={<StudentsAttendance2022 />}
        />
        <Route
          exact
          path="/students-corner/attendance/2023-2024"
          element={<StudentsAttendance2023 />}
        />
        <Route
          exact
          path="/students-corner/posters"
          element={<StudentsPosters />}
        />
        <Route exact path="/online-payment" element={<OnlinePayment />} />
        <Route exact path="/contact-us" element={<ContactUs />} />
        <Route exact path="*" element={<Navigate to="/404" />} />
        {/* <Route exact path="/404" element={<ErrorPage />} /> */}
        <Route exact path="/latestevent/event" element={<Event />} />
        <Route
          exact
          path="/latestevent/medicalcamp"
          element={<MedicalCamp />}
        />
      </Routes>
    </Suspense>
  );
};

export default Routers;
