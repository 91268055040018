import "./footer.scss";
import { Helmet } from "react-helmet-async";
import WhatsAppStickyButton from "../socialmedia/Whatsapp";
import logo from "../../assets/images/logo/JsaSiddha-png.png";
const Footer = () => {
  return (
    <section
      id="footer"
      className="p-0 wavy-footer mt-5"
      style={{ fontSize: "15px" }}
    >
      <Helmet></Helmet>
      <div className="container">
        {/* <div className="container"> */}
        <div className="row ">
          <div className="space-25">&nbsp;</div>
          <div className="col-lg-4 col-md-6 col-xs-12 feature-left space-top ">
            <div className="d-flex justify-content-center flex-wrap ">
              <img src={logo} alt="JSA siddha" className="" width={100} />
              <p className="fs-6 text-center px-4">
                We offer free Siddha treatment, prioritizing wellness. Our
                hospital also provides world-class teaching facilities for
                Siddha practices, ensuring comprehensive education and care.
              </p>
            </div>
          </div>
          <div className="col-lg-2 col-md-6 col-xs-12 feature-left space-top ">
            <div className="feature block">
              <div className="feature-icon col-md-12">
                <div className="col-md-12 h6 p-0 ">
                  <i className="fa fa-phone me-2" aria-hidden="true"></i> Call
                  Now
                </div>
              </div>
              <div className="clearfix"></div>
              <div
                className="col-md-12 "
                // style="margin-top: 30px; padding:6px 0px;"
              >
                <a href="tel:+91 75503 33113">+91 75503 33113</a>
              </div>
              <div className="clearfix"></div>
              <div className="col-md-12" style={{ padding: "6px 0px" }}>
                <a href="tel:+91 93619 09072">+91 93619 09072</a>
              </div>
              <div className="col-md-12" style={{ padding: "6px 0px" }}>
                <a href="tel:+91 63819 89939">+91 63819 89939</a>
              </div>
              <div className="col-md-12" style={{ padding: "6px 0px" }}>
                <a href="tel:+91 95662 23456">+91 95662 23456</a>
              </div>
              <div className="col-md-12" style={{ padding: "6px 0px" }}>
                <a href="tel:+91  93608 23456">+91  93608 3456</a>
              </div>
            </div>
          </div>
          <div className="col-lg-2 col-md-6 col-xs-12 feature-left space-top ">
            <div className="feature block">
              <div className="feature-icon col-md-12">
                <div className="col-md-12 h6 p-0 ">
                  <i className="fa fa-envelope me-2" aria-hidden="true"></i>
                  Email Us
                </div>
              </div>
              <div className="clearfix"></div>

              <div
                className="col-md-12 "
                // style="margin-top: 30px; padding: 7px 0px;"
              >
                <a
                  href="mailto:svtrust.educ@gmail.com"
                  className="contact-info-text"
                >
                  svtrust.educ@gmail.com
                </a>
              </div>
              <div
                className="col-md-12 "
                // style="margin-top: 30px; padding: 7px 0px;"
              >
                <a
                  href="mailto:svtrust.educ@gmail.com"
                  className="contact-info-text"
                >
                  jsasiddha@gmail.com
                </a>
              </div>
              <div className=" dropdown mb-2 jsa-groups">
                  <div
                    role="button"
                    data-bs-toggle="dropdown"
                    className="dropdown-toggle header"
                  >
                    {" "}
                    JSA GROUP OF INSTITUTIONS
                  </div>
                  <ul className="dropdown-menu ">
                    <li>
                      <a
                        href="https://jsaagri.in"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="dropdown-item"
                      >
                      JSA COLLEGE OF AGRICULTURE AND TECHNOLOGY 
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://jncw.in"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="dropdown-item"
                      >
                        JAWAHARLAL NEHRU COLLEGE FOR WOMEN
                      </a>
                    </li>
                  </ul>
                </div>
            </div>
          </div>
          <div className=" col-lg-3 offset-lg-1  col-md-6 col-xs-12 feature-left space-top">
            <div className="feature block">
              <div className="col-md-12 h6">
                <i className="fa fa-link me-2" aria-hidden="true"></i>Useful
                Links
              </div>
              <ul className="list-unstyled">
                <li>
                  <i className="fa fa-angle-double-right me-2"></i>

                  <a href="/" className=" text-decoration-none">
                    Home
                  </a>
                </li>
                <li>
                  <i className="fa fa-angle-double-right me-2"></i>
                  <a href="/about-us" className=" text-decoration-none">
                    about Us
                  </a>
                </li>
                <li>
                  <i className="fa fa-angle-double-right me-2"></i>

                  <a href="/courses" className=" text-decoration-none">
                    Courses
                  </a>
                </li>
                <li>
                  <i className="fa fa-angle-double-right me-2"></i>

                  <a
                    href="/admissions/intake_and_approval"
                    className=" text-decoration-none"
                  >
                    Admission
                  </a>
                </li>
                <li>
                  <i className="fa fa-angle-double-right me-2"></i>

                  <a href="/contact-us" className=" text-decoration-none">
                    Contact Us
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <WhatsAppStickyButton/>
        <div className="container">
          <p
            className="footer-info text-center p-0 "
            style={{ fontSize: "12px" }}
          >
            © CopyRights All Rights Reserved 2025 - <strong>Designed by</strong>
            - Shri Venkateswara Trust
          </p>
        </div>
      </div>
    </section>
  );
};

export default Footer;
