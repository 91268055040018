import "./index.scss";
export const AdmissionMarquee = () => {
  return (
    <div className="marquee">
      <ul>
        <li>
          <strong className="text-warning">
            Admission Enquiry 2025 - 2026
          </strong>
          &nbsp;&nbsp;&nbsp;**&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Affiliated to The
          TNMGRMU & Affiliated to Ministry of Ayush, Govt of India Permitted &nbsp;
          &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;**&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <i>Located In Ulundurpet, Kallakurichi(Dist.), TamilNadu – 606104.</i>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;**&nbsp;&nbsp;&nbsp;&nbsp; Contact –
          <a href="tel:+91 75503 33113" className="contact">
            {" "}
            +91 75503 33113
          </a>
          /
          <a href="tel:+9193619 09072" className="contact">



            {" "}
            +91 93619 09072
          </a>
          /
          <a href="tel: +9163819 89939" className="contact">
            {" "}
            +91 63819 89939
          </a>
          /
          <a href="tel:+919566223456" className="contact">
            {" "}
            +91 95662 23456
          </a>
          /
          <a href="tel:+91 93608 3456" className="contact">
            {" "}
            +91 93608 3456
          </a>
          {/* &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;**&nbsp;&nbsp;&nbsp;&nbsp;
          <strong>ICAR accreditation is Being Obtained</strong> ** State level
          No.1 Leading Agriculture College for Research , Innovation, Creativity
          and Discipline
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;**&nbsp;&nbsp;&nbsp;&nbsp; Our Alumni
          got 100% placement in Competitive exams of IAS, Central, State
          Governments and Banks
          &nbsp;&nbsp;&nbsp;&nbsp;**&nbsp;&nbsp;&nbsp;&nbsp; The Students are
          doing PG in various reputed Indian &amp; International Universities. */}
        </li>
      </ul>
    </div>
  );
};
