import { AdmissionMarquee } from "./admissionMrq";
import { NavHead } from "./navHead";
import "./navBar.scss";

const NavBar = () => {
  return (
    <>
      <div id="nav">
        {/* <AdmissionMarquee /> */}
        <NavHead />
      </div>
      <nav className="navbar navbar-expand-lg  navbar-light sticky-top pt-0 pb-0 ">
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navCollapse"
          aria-controls="navbarCollapse"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon "></span>
        </button>
        <div className="collapse navbar-collapse" id="navCollapse">
          <ul className="navbar-nav mr-auto d-flex flex-wrap ps-2">
            {/* <----------------Home--------------------> */}
            <li className="nav-item">
              <a href="/" className="nav-link">
                Home
              </a>
            </li>
            {/* <----------------About Us--------------------> */}
            <li className="nav-item dropdown">
              <div
                role="button"
                data-bs-toggle="dropdown"
                className="dropdown-toggle nav-link"
              >
                About us <b className="caret"></b>
              </div>
              <ul className="dropdown-menu">
                <li>
                  <a className="dropdown-item" href="/about-us">
                    About Us
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="/affiliated-univercities">
                    Affiliated Universities
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="/awards-and-acheivements">
                    Awards and Acheivements
                  </a>
                </li>
                <li className="nav-item dropdown-item  submenu">
                  <div
                    className=" text-white dropdown-toggle"
                    id="annualReport"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Annual Report
                    <i className="bi bi-chevron-right"></i>
                    {/* <i className="fa fa-caret-right"></i> */}
                  </div>
                  <ul
                    className="dropdown-menu dropdown-menu-end menu-submenu"
                    aria-labelledby="annualReport"
                  >
                    <li>
                      <a
                        className="dropdown-item"
                        href="/Annual-Report/2020-2021"
                      >
                        2020 - 2021
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        href="/Annual-Report/2021-2022"
                      >
                        2021 - 2022
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        href="/Annual-Report/2022-2023"
                      >
                        2022 - 2023
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        href="/Annual-Report/2023-2024"
                      >
                        2023 - 2024
                      </a>
                    </li>
                  </ul>
                </li>

                <li>
                  <a className="dropdown-item" href="/affiliated-univercities">
                    NCISM
                  </a>
                </li>

                <li>
                  <a className="dropdown-item" href="/affiliated-univercities">
                    Ministry of AYUSH
                  </a>
                </li>
              </ul>
            </li>
            {/* <----------------Admissions--------------------> */}
            <li className="nav-item dropdown">
              <div
                role="button"
                data-bs-toggle="dropdown"
                className="nav-link dropdown-toggle"
              >
                Admissions <b className="caret"></b>
              </div>
              <ul className="dropdown-menu">
                <li>
                  <a
                    className="dropdown-item"
                    href="https://docs.google.com/forms/d/e/1FAIpQLSfLl_114O48LQ_VavRfvpPr60vmAGHunDaE4Tn45cwk7d8MDg/viewform?embedded=true"
                  >
                    Admissions Enquiry
                  </a>
                </li>
                <li>
                  <a
                    className="dropdown-item"
                    href="/admissions/intake_and_approval"
                  >
                    Intake and Approval
                  </a>
                </li>

                <li>
                  <a className="dropdown-item" href="/Admissions/2024-2025">
                    2024-2025
                  </a>
                </li>

                <li>
                  <a className="dropdown-item" href="/Admissions/2023-2024">
                    2023-2024
                  </a>
                </li>

                <li>
                  <a className="dropdown-item" href="/Admissions/2022-2023">
                    2022-2023
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="/Admissions/2021-2022">
                    2021-2022
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="/Admissions/2020-2021">
                    2020-2021
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="/Admissions/2019-2020">
                    2019-2020
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="/Admissions/2018-2019">
                    2018-2019
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="/Admissions/2017-2018">
                    2017-2018
                  </a>
                </li>
              </ul>
            </li>
            {/* <----------------courses--------------------> */}
            <li className="nav-item dropdown">
              <div
                role="button"
                data-bs-toggle="dropdown"
                className="nav-link dropdown-toggle"
              >
                Courses <b className="caret"></b>
              </div>
              <ul className="dropdown-menu">
                <li>
                  <a className="dropdown-item" href="/courses">
                    Courses
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="/status-of-recognition">
                    Status of Recognition
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="/transitional-curriculum">
                    Transitional Curriculum
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="/results">
                    Results
                  </a>
                </li>
              </ul>
            </li>

            <li className="dropdown nav-item">
              <div
                role="button"
                data-bs-toggle="dropdown"
                className="nav-link dropdown-toggle"
              >
                Faculty <b className="caret"></b>
              </div>
              <ul className="dropdown-menu">
                <li className="nav-item dropdown-item  submenu">
                  <div
                    className=" text-white dropdown-toggle"
                    id="teaching"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Teaching Staff
                  </div>
                  <ul
                    className="dropdown-menu menu-submenu"
                    aria-labelledby="teaching"
                  >
                    <li>
                      <a
                        className="dropdown-item"
                        href="/faculty/teaching-staff/details"
                      >
                        Details
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        href="/faculty/teaching-staff/attendance"
                      >
                        Attendance Report
                      </a>
                    </li>
                  </ul>
                </li>
                <li className="nav-item dropdown-item  submenu">
                  <div
                    className=" text-white dropdown-toggle"
                    id="non-teaching"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    href="#"
                  >
                    Non-Teaching Staff
                  </div>
                  <ul
                    className="dropdown-menu menu-submenu"
                    aria-labelledby="non-teaching"
                  >
                    <li>
                      <a
                        className="dropdown-item"
                        href="/faculty/non-teaching-staff/details"
                      >
                        Details
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        href="/faculty/non-teaching-staff/attendance"
                      >
                        Attendance Report
                      </a>
                    </li>
                  </ul>
                </li>
                <li className="nav-item dropdown-item  submenu">
                  <div
                    className=" text-white dropdown-toggle"
                    id="hospital"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Hospital Staff
                  </div>
                  <ul
                    className="dropdown-menu menu-submenu"
                    aria-labelledby="hospital"
                  >
                    <li>
                      <a
                        className="dropdown-item"
                        href="/faculty/hospital-staff/details"
                      >
                        Details
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        href="/faculty/hospital-staff/attendance"
                      >
                        Attendance Report
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
            <li className="dropdown nav-item">
              <div
                role="button"
                className="nav-link dropdown-toggle"
                data-bs-toggle="dropdown"
              >
                Facilities <b className="caret"></b>
              </div>
              <ul className="dropdown-menu">
                <li className="dropdown">
                  <a className="dropdown-item" href="/facilities/college">
                    {" "}
                    College
                  </a>
                </li>
                <li className="dropdown">
                  <a className="dropdown-item" href="/facilities/hospital">
                    {" "}
                    Hospital
                  </a>
                </li>
                <li className="nav-item dropdown-item  submenu">
                  <div
                    className=" text-white dropdown-toggle"
                    id="Students"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    OP & IP CENSUS
                  </div>
                  <ul
                    className="dropdown-menu menu-submenu"
                    aria-labelledby="Students"
                  >
                   <li>
                      <a
                        className="dropdown-item"
                        href="/facilities/hospital/ip&op2025"
                      >
                        2025
                      </a>
                    </li>
                      <li>
                      <a
                        className="dropdown-item"
                        href="/facilities/hospital/ip&op2024"
                      >
                        2024
                      </a>
                    </li>
                      <li>
                      <a
                        className="dropdown-item"
                        href="/facilities/hospital/ip&op2023"
                      >
                        2023
                      </a>
                    </li>
                  <li>
                      <a
                        className="dropdown-item"
                        href="/facilities/hospital/ip&op2022"
                      >
                        2022
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        href="/facilities/hospital/ip&op2021"
                      >
                        2021
                      </a>
                    </li>
                    
                  
                   
                  </ul>
                </li>
              </ul>
            </li>
            <li className="nav-item dropdown">
              <a href="/news&updates" className="nav-link">
                News & Upcoming
              </a>
            </li>
            <li className="nav-item dropdown">
              <div
                role="button"
                data-bs-toggle="dropdown"
                className="nav-link dropdown-toggle"
              >
                Gallery<b className="caret"></b>
              </div>
              <ul className="dropdown-menu">
                <li className="nav-item dropdown-item  submenu">
                  <div
                    className=" text-white dropdown-toggle"
                    id="teaching"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    2025
                  </div>
                  <ul
                    className="dropdown-menu menu-submenu"
                    aria-labelledby="teaching"
                  >
                    <li>
                      <a
                        className="dropdown-item"
                        href="/gallery/medicalcamp/2025"
                      >
                        Medical Camp
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="/gallery/event/2025">
                        Event
                      </a>
                    </li>
                  </ul>
                </li>
                <li className="nav-item dropdown-item  submenu">
                  <div
                    className=" text-white dropdown-toggle"
                    id="teaching"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    2024
                  </div>
                  <ul
                    className="dropdown-menu menu-submenu"
                    aria-labelledby="teaching"
                  >
                    <li>
                      <a
                        className="dropdown-item"
                        href="/gallery/medicalcamp/2024"
                      >
                        Medical Camp
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="/gallery/event/2024">
                        Event
                      </a>
                    </li>
                  </ul>
                </li>
                <li>
                  <a className="dropdown-item" href="/gallery/2023">
                    2023
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="/gallery/2022">
                    2022
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="/gallery/2021">
                    2021
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="/gallery/2020">
                    2020
                  </a>
                </li>
                {/* <li>
                  <a
                    className="dropdown-item"
               
                    href="/gallery/2019"
                  >
                    2019
                  </a>
                </li> */}
                {/* <li>
                  <a
                    className="dropdown-item"

                    href="/gallery/media"

                  >
                    Press News and Tv Release
                  </a>
                </li> */}
              </ul>
            </li>

            <li className="dropdown nav-item">
              <div
                role="button"
                className="nav-link dropdown-toggle"
                data-bs-toggle="dropdown"
              >
                Others
              </div>
              <ul className="dropdown-menu">
                <li className="dropdown">
                  <a className="dropdown-item" href="/research-and-development">
                    {" "}
                    Research and Development
                  </a>
                </li>
                <li className="dropdown">
                  <a className="dropdown-item" href="/cme-webinar">
                    {" "}
                    CME Webinar Programs
                  </a>
                </li>
              </ul>
            </li>
            <li className="nav-item dropdown">
              <div
                role="button"
                data-bs-toggle="dropdown"
                className="nav-link dropdown-toggle"
              >
                Students Corner <b className="caret"></b>
              </div>
              <ul className="dropdown-menu">
                <li className="nav-item dropdown-item  submenu">
                  <div
                    className=" text-white dropdown-toggle"
                    id="Students"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Students Attendance
                  </div>
                  <ul
                    className="dropdown-menu menu-submenu"
                    aria-labelledby="Students"
                  >
                    <li>
                      <a
                        className="dropdown-item"
                        href="/students-corner/attendance/2022-2023"
                      >
                        2022-2023
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        href="/students-corner/attendance/2023-2024"
                      >
                        2023-2024
                      </a>
                    </li>
                  </ul>
                </li>
                <li>
                  <a className="dropdown-item" href="/students-corner/posters">
                    Posters by Students
                  </a>
                </li>
              </ul>
            </li>

            <li className="dropdown nav-item">
              <div
                role="button"
                className="nav-link dropdown-toggle"
                data-bs-toggle="dropdown"
              >
                Latest Event
              </div>
              <ul className="dropdown-menu">
                <li className="dropdown">
                  <a className="dropdown-item" href="/latestevent/medicalcamp">
                    {" "}
                    Medical Camp
                  </a>
                </li>
                <li className="dropdown">
                  <a className="dropdown-item" href="/latestevent/event">
                    {" "}
                    Event
                  </a>
                </li>
              </ul>
            </li>
            <li className="nav-item">
              <a href="/online-payment" className="nav-link">
                Online Payment
              </a>
            </li>
            <li className="nav-item">
              <a href="/contact-us" className="nav-link">
                Contact Us
              </a>
            </li>
            {/* <li className="nav-item">
              <a href="/latestevent" className="nav-link">
                <span class="badge text-bg-warning">Latest Event</span>
              </a>
            </li> */}
          </ul>
        </div>
      </nav>
    </>
  );
};
export default NavBar;
